<template>
  <div style="margin-top: 22px; margin-bottom: 12px">
    <h6>Daily Upload Progress</h6>

    <!-- Прогресс бар -->
    <div v-if="dailyVideosCount < 5" class="progress-container">
      <el-progress :percentage="progress" :text-inside="true" stroke-width="14"></el-progress>
      <span>Upload {{ 5 - dailyVideosCount }} more video(s) to earn $0.50 reward</span>
    </div>
    <div v-else class="reward-message">
      <span>You've earned $0.50 today for uploading 5 videos!</span>
    </div>
  </div>
</template>


<script>
import { ref, onMounted } from 'vue';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '@/firebase/init';
import dayjs from 'dayjs';

export default {
  name: 'DailyProgress',
  props: {
    userId: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const dailyVideosCount = ref(0);
    const progress = ref(0);

    // Функция для получения видео за текущие сутки со статусом PUBLISH_COMPLETE
    const fetchDailyVideos = async () => {
      const todayStart = dayjs().startOf('day').unix(); // Начало текущего дня в формате UNIX
      const q = query(
          collection(db, 'videos'),
          where('userId', '==', props.userId),
          where('status', '==', 'PUBLISH_COMPLETE'), // Фильтр по статусу
          where('createTime', '>=', todayStart) // Фильтр по времени загрузки за текущие сутки
      );
      const querySnapshot = await getDocs(q);
      dailyVideosCount.value = querySnapshot.size;

      // Вычисляем прогресс (сколько процентов до 5 видео)
      progress.value = Math.min((dailyVideosCount.value / 5) * 100, 100);
    };

    onMounted(async () => {
      await fetchDailyVideos();
    });

    return {
      dailyVideosCount,
      progress,
    };
  },
};
</script>

<style lang="scss" scoped>
.progress-container {
  margin-bottom: 12px;
}
.reward-message {
  margin-top: 12px;
}

h6 {
  margin-bottom: 12px;
  display: flex;
}
h6, span {
  color: #fff;
}

span {
  background: rgba(255, 255, 255, 0.08) !important;
  padding: 18px 16px;
  box-sizing: border-box;
  display: flex;
  margin-top: 12px;
  border-radius: 12px;
}
</style>
