const menu_data= [
    {
        id: 1,
        page:"nav_2",
        has_dropdown: false,
        title: "Offers",
        link: "/offers",
    },
    {
        id: 5,
        page:"nav_2",
        has_dropdown: false,
        title: "Profile",
        link: "/profile",
    },
    {
        id: 2,
        page:"nav_2",
        has_dropdown: false,
        title: "Wallet",
        link: "/wallet",
    },
    {
        id: 3,
        page:"nav_2",
        has_dropdown: false,
        title: "Settings",
        link: "/settings",
    },
    {
        id: 6,
        page:"nav_2",
        has_dropdown: false,
        title: "Referral",
        link: "/referral",
    },
];
export default menu_data;
