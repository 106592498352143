<template>
  <div>
    <div class="off-er">
      <div class="offer-preview" style="background-image: url('/pr.jpg')">
        <div class="text-offer">
          <h3>Publish videos and earn money</h3>
          <h5>Choose a video you like, post it on TikTok,
            upload 5 times daily to earn bonuses,
            and get paid based on your video views.</h5>
          <a href="https://telegra.ph/How-to-get-started-StreamSalexyz-09-27" target="_blank" class="btn" style="margin-top: 20px; font-size: 18px">How it works</a>
        </div>
      </div>
    </div>

    <div class="offers">
      <div class="title-offers">
        <h2 class="title" style="margin-bottom: 32px">{{ $t('all_offers') }}</h2>
      </div>

      <div class="filters">
        <el-select style="max-width: 200px" v-model="selectedCurrency" placeholder="Select Currency" clearable>
          <el-option v-for="currency in uniqueCurrencies" :key="currency" :label="currency" :value="currency"/>
        </el-select>

        <div @click="togglePriceSort" class="sort-price">
          <span style="margin-right: 8px">Price</span>
            <el-icon v-if="priceSortOrder !== 'asc'"><CaretBottom /></el-icon>
            <el-icon v-else><CaretTop /></el-icon>
        </div>
      </div>
      <div class="offers-cards">

        <OfferCard v-for="task in filteredAndSortedTasks" :key="task.id" :card="{
        ...task
      }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OfferCard from "@/components/OfferCard";
import {onMounted, ref, computed} from 'vue';
import {db} from '@/firebase/init';
import {collection, getDocs, query, where} from 'firebase/firestore';
import {orderBy} from "lodash";

export default {
  name: "OffersPage",
  components: {OfferCard},
  setup() {
    const tasks = ref([]);
    const selectedCurrency = ref("");
    const priceSortOrder = ref("asc");

    // Получение уникальных валют для фильтра
    const uniqueCurrencies = computed(() => {
      const currencies = tasks.value.map(task => task.coin?.name || "");
      return [...new Set(currencies)].filter(currency => currency);
    });

    const fetchTasks = async () => {
      const q = query(collection(db, "tasks"), where("hide", "!=", true));
      const querySnapshot = await getDocs(q);
      tasks.value = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
    };

    onMounted(fetchTasks);

    // Фильтрация и сортировка задач
    const filteredAndSortedTasks = computed(() => {
      let filteredTasks = tasks.value;

      // Фильтр по валюте
      if (selectedCurrency.value) {
        filteredTasks = filteredTasks.filter(task => task.coin?.name === selectedCurrency.value);
      }

      // Сортировка по цене
      return orderBy(filteredTasks, ['price'], [priceSortOrder.value]);
    });

    // Функция для переключения порядка сортировки
    const togglePriceSort = () => {
      priceSortOrder.value = priceSortOrder.value === 'asc' ? 'desc' : 'asc';
    };

    return {
      tasks,
      selectedCurrency,
      priceSortOrder,
      filteredAndSortedTasks,
      uniqueCurrencies,
      togglePriceSort,
    };
  },
};
</script>

<style scoped lang="scss">
.title-offers {

  display: flex;
  justify-content: flex-start;
  max-width: 1024px;
  margin: 32px auto 0 auto;
  width: calc(100% - 16px);
}

.offers {
  max-width: 1024px;
  margin: 32px auto;
  min-height: 420px;
  width: calc(100% - 16px);
}

.offers-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px 16px;
}

@media only screen and (max-width: 600px) {
  .offers-cards {
    display: flex;
    flex-direction: column;
    max-width: 440px;
    width: calc(100% - 16px);
    margin: 0 auto;
  }
}

.offer-preview {
  width: 100%;
  height: 420px;
  max-height: 443px;
  object-fit: cover;
  border-radius: 20px;
  max-width: 1024px;
  background-size: cover;
  background-position: center center;
  margin: 16px auto 32px auto;
  display: flex;
  flex-direction: column;
  padding: 72px;

  h3 {
    font-size: 42px;
    font-weight: bold;
  }

  h5 {
    font-size: 20px;
    color: #fff;
    font-weight: normal;
  }
}

.text-offer {
  width: 60%;
}

.off-er {
  display: flex;
  justify-content: center;
}


@media only screen and (max-width: 600px) {
  .offer-preview {
    max-width: 1024px;
    border-radius: 0 !important;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0 !important;

    h3 {
      font-size: 32px;
    }

    h4 {
      font-size: 18px;
    }
  }

  .text-offer {
    width: 80%;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
  margin-top: -18px;
  flex: 1;
}

.sort-price {
  height: 48px;
  color: #fff;
  display: flex;
  background: rgba(255, 255, 255, 0.15) !important;
  border-radius: 20px !important;
  height: 48px !important;
  font-size: 16px !important;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
