<template>
  <div class="login-page center-container">
    <h2 class="title">{{$t('sing_in')}}</h2>
    <div class="main-form login-page-form">
      <div class="group-input">
        <label class="main-label">{{$t('email')}}</label>
        <input v-model="email" type="email"  class="main-input"/>
      </div>
      <div class="group-input">
        <label class="main-label">{{$t('password')}}</label>
        <input v-model="password" type="password" class="main-input" />
      </div>
      <button class="btn-solid login-button"  @click="login">{{$t('login')}}</button>
      <div class="registration-container">
        <router-link to="/registration">
          <button class="btn-main login-reg-button">{{$t('registration')}}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase/init';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import { ref } from 'vue';
import {useRouter} from "vue-router";
import {fetchTikTokProfiles} from "@/firebase/queries";

export default {
  name: "LoginIndex",
  setup() {
    const email = ref('');
    const password = ref('');
    const router = useRouter();

    const login = () => {
      signInWithEmailAndPassword(auth, email.value, password.value)
          .then(async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            const userUid = user.uid;
            const prof = await fetchTikTokProfiles(userUid);

            if(prof && prof.length) {
              router.push('/offers')
            } else {
              router.push('/connect')
            }
          })
          .catch(error => {
            alert(error.message);
          });
    };

    return { email, password, login };
  }
}
</script>

<style scoped lang="scss">
.login-page {
  margin-top: 80px;
  margin-bottom: 80px;
}

.login-page-form {
  max-width: 382px;
  width: calc(100% - 16px);

  .group-input {
    width: 100%;
    margin-top: 32px;
  }
}

.login-button {
  margin-top: 24px;
}


.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-top: 32px;
}

.login-reg-button {
  height: 30px;

}
</style>
