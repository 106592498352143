<template>
  <div class="language">
    <el-select
        v-model="currentLanguage" @change="changeLanguage"
        clearable
        style="width: 140px"
    >
      <el-option v-for="lang in languages" :key="lang.code" :label="lang.label" :value="lang.code">
        {{ lang.label }}
      </el-option>
    </el-select>

  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { locale } = useI18n();
    const languages = [
      { code: 'en', label: 'English' },
      { code: 'pt', label: 'Português' },
      { code: 'es', label: 'Español' },
      // Добавьте другие языки по мере необходимости
    ];

    const changeLanguage = (event) => {
      locale.value = event;
    };

    return {
      currentLanguage: locale,
      languages,
      changeLanguage,
    };
  },
};
</script>

<style>
.language {
  padding: 0 8px;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px
}
select {
  color: #fff;
  outline: none;
  background: rgba(0,0,0,0);
  border: none;
}
/* Вы можете добавить стили для компонента выбора языка здесь */
</style>
