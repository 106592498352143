<template>
  <div :class="['creative-card', {'active-card': videoActive, 'inline': inline}]">
    <el-icon class="close-icon" v-if="videoActive" @click="close"><CloseBold /></el-icon>
    <div>
        <div class="play-btn" @click="play">
          <img src="@/assets/play.svg">
        </div>
      <img v-if="!videoActive && src_preview" ref="video"  :src="src_preview" class="video"/>
      <img v-else-if="!videoActive && !src_preview" ref="video"  src="@/assets/default.svg" class="video"/>

      <video  v-else ref="video"  :src="src" loop :controls="videoActive" class="video"/>
        <router-link v-if="videoId" :to="`/offer/${id}/video/${videoId}`" class="offer-footer">
          <button class="btn-solid" v-if="!lock">{{$t('select')}}</button>
          <span v-else class="already">{{$t('already_published_this_video')}}</span>
        </router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: "CreativeCard",
  props: {
    src: String,
    inline: Boolean,
    src_preview: String,
    id: String,
    videoId: String,
    lock: Boolean,
    card: {
      type: Object,
      default: () => ({
        title: '',
        description: '',
        price: 0,
        currentUsers: 0,
        maxUsers: 0
      })
    },
  },
  data() {
    return {
      videoActive: false
    }
  },
  methods: {
    play() {
      this.videoActive = true
      setTimeout(() => {
        this.$refs.video.play()
      }, 500)
    },
    close() {
      this.videoActive = false
      this.$refs.video.pause()
      this.$refs.video.currentTime = 0;
    }
  }
}
</script>

<style scoped lang="scss">
.creative-card {
  width: 100%;
  height: 420px;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  border-radius: 18px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.15);
  max-width: 280px;

  &.inline {
    width: 100% !important;
    max-width: 100% !important;

    .video {
      max-width: 100% !important;

    }
  }


  &.inline.active-card {
    width: 100vw;
    height: 100vh;
    .video {
      max-width: 420px !important;
    }
  }


  .offer-footer {
    position: relative;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    padding: 0 16px;
    box-sizing: border-box;

    .btn-solid {
      width: 100%;
      z-index: 99;
    }
  }

  .play-btn {
    cursor: pointer;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 9;
    align-items: center;
    cursor: pointer;
    img {
      width: 72px;
      height: 72px;
    }
  }

  .video {
    height: 420px;
    width: 100%;
    object-fit: cover;
    max-width: 280px;
  }
}

.close-icon {
  cursor: pointer;
  z-index: 1000;
  position: fixed;
  right: 32px;
  top: 32px;
  font-size: 33px;
  color: #fff;
}
.active-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  max-width: unset !important;

  background: rgba(0,0,0,0.5);
  border-radius: 0;
  height: 100%;
  z-index: 999;
  border: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .video {
    max-width: 400px;
    height: 50%;
    border-radius: 18px;
    overflow: hidden;
  }

  .play-btn {
    display: none;
  }

  .offer-footer {
    display: none;
  }
}

.already {
  font-size: 16px;
  font-weight: 300;
  font-family: Roboto;
  text-align: center;
  color: #fff;
  margin-top: 30px;
}
</style>
