import { createRouter, createWebHistory } from 'vue-router';

import LoginPage from '../pages/auth/LoginPage';
import RegistrationPage from '../pages/auth/RegistrationPage';
import OffersPage from '../pages/user/OffersPage';
import CurrentOfferPage from '../pages/user/CurrentOfferPage';
import UserProfile from '../pages/user/UserProfile';
import UserSettings from '../pages/user/UserSettings';
import WalletPage from "@/pages/user/WalletPage";
import CreateVideoPage from '../pages/user/CreateVideoPage';
import ReferralPage from '../pages/user/ReferralPage';

import AdminPage from "@/pages/admin/AdminPage";
import TasksPage from "@/pages/admin/task/TasksPage";
import CoinsPage from "@/pages/admin/coins/CoinsPage";
import CreateCoinPage from "@/pages/admin/coins/CreateCoinPage";

import CreateTasksPage from "@/pages/admin/task/CreateTasksPage";
import ProfilesPage from "@/pages/admin/profile/ProfilesPage";

import TaskViewPage from "@/pages/admin/task/TaskViewPage";
import TxViewPage from "@/pages/admin/transaction/TransactionsPage";

import ProfileViewPage from "@/pages/admin/profile/ProfileViewPage";

import { auth } from '@/firebase/init';
import ConnectTiktok from "@/pages/user/ConnectTiktok";

const routes = [
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/registration',
        name: 'RegistrationPage',
        component: RegistrationPage,
    },
    {
        path: '/referral',
        name: 'ReferralPage',
        component: ReferralPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'OffersPageMain',
        component: OffersPage,
        meta: {
            all: true
        }
    },
    {
        path: '/offers',
        name: 'OffersPage',
        component: OffersPage,
        meta: {
            all: true
        }
    },
    {
        path: '/offer/:id/',
        name: 'CurrentOfferPage',
        component: CurrentOfferPage,
        meta: {
            all: true
        }
    },
    {
        path: '/offer/:id/video/:videoId',
        name: 'CreateVideo',
        component: CreateVideoPage,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/profile',
        name: 'UserProfile',
        component: UserProfile,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/connect',
        name: 'ConnectTikTok',
        component: ConnectTiktok,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/settings',
        name: 'UserSettings',
        component: UserSettings,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/wallet',
        name: 'WalletPage',
        component: WalletPage,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/admin',
        component: AdminPage,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'Tasks',
                component: TasksPage,
            },
            {
                path: 'create-task',
                name: 'CreateTask',
                component: CreateTasksPage,
            },
            {
                path: 'profiles',
                name: 'Profiles',
                component: ProfilesPage,
            },
            {
                path: 'profile/:id',
                name: 'profileViewAdminPage',
                component: ProfileViewPage,
            },
            {
                path: 'profileUser/:id',
                name: 'UserProfileAdmin',
                component: UserProfile,
            },
            {
                path: 'task/:id',
                name: 'ViewTask',
                component: TaskViewPage,
            },
            {
                path: 'create-coin',
                name: 'CreateCoin',
                component: CreateCoinPage,
            },
            {
                path: 'coins',
                name: 'CoinsList',
                component: CoinsPage,
            },
            {
                path: 'transactions',
                name: 'ViewTransactions',
                component: TxViewPage,
            },
            {
                path: 'edit-task/:id',
                name: 'edit-task-admin',
                component: CreateTasksPage,
            },
            {
                path: 'edit-coin/:id',
                name: 'edit-coin-admin',
                component: CreateCoinPage,
            },
            {
                path: 'view-offer/:id',
                name: 'view-offer-admin',
                component: CurrentOfferPage,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.path.startsWith('/app')) {
        const newPath = to.path.replace('/app', '');  // Убираем '/app' из пути
        next({ path: newPath, query: to.query, replace: true });  // Перенаправляем на новый путь
    } else {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        const requiresAll = to.matched.some(record => record.meta.all);

        if (requiresAll) {
            next();
        }

        const isAuthenticated = auth.currentUser;

        if (isAuthenticated && !requiresAuth) {
            next('/offers');
        }

        if (requiresAuth && !isAuthenticated) {
            next('/login');
        } else {
            next();
        }
    }
});


export default router;
