<template>
  <div :class="['offer-card', {'is_scam': card.is_scam}]" v-if="card && card.task">
    <div class="offer-card-container">
      <CreativeCard v-if="card.task && card.task.videos" class="offer-preview"
                    :src_preview="card.task.videos.find(({id}) => card.creativeId === id)?.preview_href || null"
                    :src="card.task.videos.find(({id}) => card.creativeId === id)?.href || null" inline/>

      <div class="offer-body tiktok-upload" v-if="card.status === 'SEND_TO_USER_INBOX'">
        <img src="@/assets/upload-tiktok.svg"/>
        <p>
          {{
            $t('the_video_has_been_sent_to_your_tiktok_profile_please_click_on_your_inbox_notifications_to_continue_the_editing_flow_in_tiktok_and_complete_your_post_')
          }}</p>
        <!--        <div class="btn-solid force-publish">{{$t('force_publish_now')}}</div>-->
      </div>
      <div class="offer-body tiktok-upload" v-else-if="card.status === 'PROCESSING_UPLOAD'">
        <img src="@/assets/process-tiktok.svg"/>

        <p>{{ $t('the_video_is_being_published_please_wait_a_moment_') }}</p>
        <!--        <div class="btn-solid force-publish">{{$t('force_publish_now')}}</div>-->
      </div>
      <div v-else class="offer-body">
        <div class="offer-title-container">
          <div class="offer-title">{{ card.title }}</div>
        </div>
        <div>
          <span class="offer-description" v-html="formattedDescription"></span>
        </div>


        <!--        <div class="progress-container">-->
        <!--&lt;!&ndash;          <div class="progress-title-container">&ndash;&gt;-->

        <!--&lt;!&ndash;            <span class="progress-title">{{$t('total_marketing_budget')}}</span>&ndash;&gt;-->
        <!--&lt;!&ndash;            <strong class="progress-percent">{{  card.task.companyBudget }} {{card.task?.coin?.name || 'POINT'}} </strong>&ndash;&gt;-->
        <!--&lt;!&ndash;          </div>&ndash;&gt;-->
        <!--&lt;!&ndash;          <div class="progress">&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="progress-active" :style="{ width: `${calculatePercentage(card.task.totalSum || 0, card.task.companyBudget)}%` }"></div>&ndash;&gt;-->
        <!--&lt;!&ndash;          </div>&ndash;&gt;-->
        <!--        </div>-->
        <span class="stat-every">{{ $t('the_statistics_are_updated_every_10_minutes_') }}</span>

        <div class="offer-participants" style="border-top: none">
          <span class="offer-label">{{ $t('total_views') }}</span>
          <div class="participants-count">
            <strong>{{ card.view_count || 0 }}</strong>
          </div>
        </div>
        <div class="offer-participants">
          <span class="offer-label">{{ $t('likes') }}</span>
          <div class="participants-count">
            <strong>{{ card.like_count || 0 }}</strong>
          </div>
        </div>
        <div class="offer-participants">
          <span class="offer-label">{{ $t('comments') }}</span>
          <div class="participants-count">
            <strong>{{ card.comment_count || 0 }}</strong>
          </div>
        </div>
        <div class="offer-participants">
          <span class="offer-label">{{ $t('amount_earned') }}</span>
          <div class="participants-count">
            <strong>{{ parseFloat(Number(card.view_count * (card.task.price / 1000)).toFixed(8)) || 0 }}
              {{ card.task?.coin?.name || 'POINT' }}</strong></div>
        </div>
        <div class="offer-footer">
          <a :href="card.share_url" target="_blank">
            <img v-if="card.type === 'TIKTOK'" src="@/assets/tiktok-white.svg" class="tiktok-img">
            <img v-if="card.type === 'YOUTUBE'" src="@/assets/youtube.svg" class="tiktok-img">

          </a>
          <router-link :to="`/offer/${card.task && card.task.id}`">
            <button class="btn-solid">{{ $t('view_offer_1') }}</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreativeCard from "@/components/CreativeCard";

export default {
  name: "ActiveOfferCard",
  components: {CreativeCard},
  props: {
    card: {
      type: Object,
      default: () => ({
        title: '',
        description: '',
        price: 0,
        currentUsers: 0,
        maxUsers: 0
      })
    }
  },
  methods: {
    calculatePercentage(part, whole) {
      return Math.ceil((part / whole) * 100);
    }
  },
  computed: {
    formattedDescription() {
      if (!this.card || !this.card.description) return '';
      // eslint-disable
      const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig; // eslint-disable-line
      return this.card.description.replace(urlPattern, '<a class="link-description" href="$1" target="_blank">$1</a>');
    }
  }
}
</script>

<style scoped lang="scss">
.offer-card-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.offer-card {
  max-width: 100%;
  overflow: hidden;
  border-radius: 18px;
  padding-bottom: 12px;
  background: rgba(255, 255, 255, 0.08);

  &.is_scam {
    box-shadow: 0px 0px 5px 4px rgba(255, 0, 0, 0.5);
  }

  .progress-title-container {
    display: flex;
    justify-content: space-between;
  }

  .offer-preview {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .offer-title {
    color: #fff;
    font-size: 18px;
    max-width: 80%;
    font-family: Roboto;
    font-weight: 600;
  }

  .offer-title-container {
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 12px;
    box-sizing: border-box;
    display: flex;
    margin-top: -101px;
    height: 100px;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  }

  .offer-body {
    padding: 0 12px 4px 12px;
  }

  .offer-description, .offer-label {
    display: inline;
    font-size: 14px;
    color: #eee;
    font-weight: 300;
    font-family: Roboto;
  }

  .offer-description {
    margin-top: 20px;
    margin-bottom: 8px;
    white-space: pre-wrap;
  }


  .offer-participants {
    padding: 12px 0;
    border-top: 1px solid #222;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      border-top: none !important;
    }

  }

  .participants-count {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    font-family: Roboto;
  }

  .offer-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    .btn-solid {
      font-size: 14px;
      padding-left: 18px;
      padding-right: 18px;
      height: 48px;
    }
  }


  .offer-amount {
    color: #fff;
    display: flex;
    align-items: center;
  }

  .hot-participant {
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }

  .usdt-offer {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
}

.progress-container {
  display: flex;
  flex: 1;
  margin-bottom: 12px;
  margin-top: 24px;
  flex-direction: column;

  .progress-percent {
    color: #fff;
    font-size: 12px;
  }

  .progress {
    height: 3px;
    border-radius: 2px;
    background: #264A2E;
    width: 100%;

    .progress-active {
      background: #76FF96;
      height: 3px;
      border-radius: 2px;
    }
  }

  .progress-title {
    color: #fff;
    font-size: 12px;
    margin-bottom: 8px;
  }
}

.tiktok-upload {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  margin-bottom: 18px;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 24px 8px !important;
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;

  img {
    width: 72px;
    height: 72px;
    margin-bottom: 24px;
    opacity: 0.9;
  }

  p {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    opacity: 0.9;
  }
}

.stat-every {
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 6px;
  display: flex;
  color: #fff;

}

.force-publish {
  margin-top: 20px;
}

.tiktok-img {
  width: 38px;
}

.offer-card .offer-title {
  overflow: hidden;
  text-wrap: nowrap !important;
  max-width: 300px;
  width: 100% !important;
  text-overflow: ellipsis;
}
</style>
