<template>
  <div class="wallet">
    <div class="settings-container" v-loading="loader">
      <div class="wallet-info">
        <div class="user-title">
          <h2 class="title">{{ $t('wallet') }}</h2>
        </div>

        <div class="withdrawal-container">
          <h4>{{ $t('withdrawal') }}</h4>
        </div>

        <div class="group-input coins">
          <label class="main-label">{{ $t('select_currency') }}</label>
          <select class="main-input" v-model="selectedCurrency">
            <option v-for="coin in coins" :key="coin.symbol" :value="coin.symbol">
              {{ coin.name }}
            </option>
          </select>
        </div>

        <div class="main-form wallet-form">
          <div class="group-input">
            <label class="main-label">{{ $t('amount') }}</label>
            <input

                :min="0"
                :max="totalAmount"
                @input="validateAmount"
                type="number" class="main-input" v-model="amount"/>
          </div>
          <div v-if="amountError" class="error">{{ amountError }}</div>

          <div class="group-input" style="margin-top: 20px" v-if="selectedCurrency === 'usdt_trc_20'">
            <label class="main-label">{{ $t('usdt_address_trc20') }}</label>
            <input class="main-input" v-model="address"/>
          </div>

          <div class="group-input" style="margin-top: 20px" v-if="selectedCurrency === 'OGC'">
            <label class="main-label">{{ $t('email') }}</label>
            <input class="main-input" v-model="email"/>
          </div>

          <div class="group-input" style="margin-top: 20px" v-if="selectedCurrency === 'Reais'">
            <label class="main-label">{{ $t('pix_key') }}</label>
            <input class="main-input" v-model="pix_key"/>
          </div>

          <div class="group-input" style="margin-top: 20px" v-if="selectedCurrency === 'Reais'">
            <label class="main-label">{{ $t('first_name') }}</label>
            <input class="main-input" v-model="first_name"/>
          </div>

          <div class="group-input" style="margin-top: 20px" v-if="selectedCurrency === 'Reais'">
            <label class="main-label">{{ $t('last_name') }}</label>
            <input class="main-input" v-model="last_name"/>
          </div>

          <div class="group-input" style="margin-top: 20px" v-if="selectedCurrency === 'Reais'">
            <label class="main-label">{{ $t('document_id') }}</label>
            <input class="main-input" v-model="document_id"/>
          </div>

          <div class="manual">
            <div style="margin-top: -20px">
              <div class="dot-container">
                <PulseDot/>
              </div>
              <span style="margin-left: 42px">Please provide your Telegram or WhatsApp contact details so we can reach out to you if there are any issues with your payment.</span>
            </div>
          </div>

          <div class="group-input" style="margin-top: 20px" v-if="selectedCurrency === 'Reais'">
            <label class="main-label">Telegram or Whatsapp</label>
            <input class="main-input" v-model="contact"/>
          </div>


          <div class="min-amount-description">
            {{ $t('min_withdraw') }} {{ getMinAmount(selectedCurrency) }} {{ selectedCurrency }}
          </div>

          <button class="btn-solid save-password" :disabled="amount < getMinAmount(selectedCurrency)" @click="sendRequest">
            {{ $t('send_request') }}
          </button>

        </div>
      </div>
      <div class="wallet-info transactions">
        <div class="offer-participants">
          <h4 style="margin-top: 10px; margin-bottom: 32px">{{ $t('balance') }}</h4>
          <div>
            <div class="participants-count" v-if="totalAmount">
              <img class="icon-info" src="@/assets/usdt.svg">
              <strong>{{ totalAmount }} POINT</strong>
            </div>


            <div class="participants-count" v-for="coin in coins" :key="coin.symbol"
                 v-show="balances && balances[coin.symbol]">
              <img class="icon-info" :src="coin.preview">
              <strong v-if="balances">{{ parseFloat(Number(balances[coin.symbol]).toFixed(6)) }} {{ coin.name }}</strong>
            </div>
          </div>
        </div>

        <h4 style="margin-top: 16px">{{ $t('transactions') }}</h4>

        <div class="transactions-container">
          <div class="transactions-row" v-for="tx in reansactions" :key="tx.id">
            <div class="tx-amount-container">
              <strong class="tx-amount">{{ tx.type === 'withdraw' ? '-' : '+' }}{{ tx.sum }} {{ tx.currency }}</strong>
              <div class="tx-status">
                <el-icon class="green-tx" v-if="tx.status === 'done'">
                  <CircleCheck v-if="tx.status === 'done'"/>
                </el-icon>
                <el-icon class="orange-tx" v-if="tx.status === 'wait'">
                  <Clock v-if="tx.status === 'wait'"/>
                </el-icon>
                <el-icon class="red-tx" v-if="tx.status === 'reject'">
                  <CircleCloseFilled v-if="tx.status === 'reject'"/>
                </el-icon>
                <span v-if="tx.status === 'reject'" class="red-tx">{{ tx.status.toUpperCase() }}</span>
                <span v-if="tx.status === 'done'" class="green-tx">{{ tx.status.toUpperCase() }}</span><span
                  v-if="tx.status === 'wait'" class="orange-tx">{{ tx.status.toUpperCase() }}</span></div>

            </div>
            <span class="tx-ref">
              <div><strong class="strong-title">{{ tx.type }}</strong></div>
              <div v-if="tx.info" class="info-tx">
               <span v-for="[key, info] in Object.entries(tx.info)" :key="info"
                     v-show="info">{{ key.replace('_', ' ') }}: <strong class="stg">{{ info }}</strong></span>
                </div>
            </span>
            <span class="tx-date">{{ tx.datetime }}</span>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import {addDoc, collection, doc, getDocs, query, where} from "firebase/firestore";
import {db} from "@/firebase/init";
import dayjs from 'dayjs'
import {fetchTikTokProfiles, getBalances, getWithdrawal} from "@/firebase/queries";
import {getAuth} from "firebase/auth";
import {setDoc} from 'firebase/firestore';
import {ElNotification} from "element-plus";
import {orderBy} from 'lodash';
import PulseDot from "@/components/PulseDot";


export default {
  name: "WalletPage",
  components: {PulseDot},
  setup() {
    const address = ref('');
    const reansactions = ref([])
    const profiles = ref([]);
    const amount = ref('')
    const withdrawalBalance = ref(null);

    const totalAmount = ref(0)
    const amountError = ref('');
    const loader = ref(false)
    const balances = ref(null);

    const email = ref('')

    const pix_key = ref('')
    const first_name = ref('')
    const last_name = ref('')
    const document_id = ref('')
    const contact = ref('')

    const auth = getAuth();
    const user = auth.currentUser;
    const userUid = user.uid;

    const coins = ref([]);
    const selectedCurrency = ref(null);


    const fetchCoins = async () => {
      const q = await query(collection(db, "coins"));
      const queryCoinsSnapshot = await getDocs(q);
      coins.value = queryCoinsSnapshot.docs.map((doc) => doc.data());
      if (coins.value.length > 0) {
        selectedCurrency.value = coins.value[0].symbol;
      }
    };

    const getMinAmount = (coin) => {
      return (coins.value.find(({symbol}) => symbol === coin))?.minAmount || 0
    }

    watch(selectedCurrency, () => {
      amount.value = 0
    });

    const validateAmount = () => {

      if(!balances.value[selectedCurrency.value]) {
        amountError.value = 'Amount cannot be less than 0';
        amount.value = 0;
        return
      }

      console.log(balances.value[selectedCurrency.value], 'balances.value[selectedCurrency.value]')
      if (amount.value < 0) {
        amountError.value = 'Amount cannot be less than 0';
        amount.value = 0;
      } else if (amount.value > balances.value[selectedCurrency.value]) {
        amountError.value = `Amount cannot be more than ${balances.value[selectedCurrency.value]}`;
        amount.value = balances.value[selectedCurrency.value]
      } else {
        amountError.value = '';
      }
    };

    const fetchTransactions = async () => {
      console.log(userUid, 'userUid')

      const q = await query(collection(db, 'transactions'), where('userUid', '==', userUid));
      const queryVideosSnapshot = await getDocs(q);
      reansactions.value = orderBy(queryVideosSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        datetime_unix: doc.data().datetime,
        datetime: dayjs(doc.data().datetime * 1000).format('YYYY-MM-DD HH:mm')
      })), 'datetime_unix', ['desc']);

      console.log(reansactions.value, 'reansactions.value')
    };

    const sendRequest = async () => {
      try {

        if(!balances.value[selectedCurrency.value]) {
          return
        }
        if (amount.value > balances.value[selectedCurrency.value]) {
          return
        }


        if(amount.value < getMinAmount(selectedCurrency.value)) {
          return
        }
        loader.value = true


        const withdrawalRef = doc(db, 'withdrawal', userUid)

        const withdrawalBalanceData = withdrawalBalance.value || {}

        await setDoc(withdrawalRef, {
            ...withdrawalBalanceData,
            [selectedCurrency.value]: withdrawalBalanceData[selectedCurrency.value] ? withdrawalBalanceData[selectedCurrency.value] + amount.value : amount.value
        });


        ElNotification({
          title: 'Success',
          message: 'Withdrawal request has been successfully sent',
          type: 'success',
        });


        console.log(userUid, 'userUid')
        await addDoc(collection(db, 'transactions'), {
          userUid: userUid,
          sum: Number(amount.value),
          status: 'wait',
          currency: selectedCurrency.value,
          profile: profiles.value[0],
          info: {
            pix_key: pix_key.value,
            email: email.value,
            address: address.value,
            first_name: first_name.value,
            last_name: last_name.value,
            document_id: document_id.value
          },
          type: 'withdraw',
          datetime: dayjs().unix(),
        });

        fetchTransactions();
        fetchProfile();

        loader.value = false

      } catch (error) {
        loader.value = false
        console.error(error)
      }
    }

    const fetchProfile = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      const userUid = user.uid;
      const profile = await fetchTikTokProfiles(userUid);


      const balancesResponse = await getBalances(userUid);
      const balanceResult = {};

      const withdrawalBalanceResponse = await getWithdrawal(userUid)

      console.log(withdrawalBalanceResponse, 'withdrawalBalanceResponse')

      try {
        if (balancesResponse) {
          Object.entries(balancesResponse.balances).forEach(([key, value]) => {
            if(withdrawalBalanceResponse) {
              const withdrawal = (withdrawalBalanceResponse && withdrawalBalanceResponse[key]) || 0;
              balanceResult[key] = parseFloat(Number(value - withdrawal).toFixed(6));
            } else {
              balanceResult[key] = parseFloat(Number(value).toFixed(6));
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
      balances.value = balanceResult;
      withdrawalBalance.value = withdrawalBalanceResponse;
      profiles.value = profile
      totalAmount.value = (profile && profile[0] && ((profile[0].balance || 0) - (profile[0].locked || 0)))
    }


    onMounted(async () => {

      fetchProfile();
      fetchCoins();
      fetchTransactions()
    });


    return {
      address,
      email,
      pix_key,
      first_name,
      last_name,
      document_id,
      balances,
      profiles,
      coins,
      selectedCurrency,
      amount,
      amountError,
      loader,
      getMinAmount,
      sendRequest,
      reansactions,
      totalAmount,
      contact,
      validateAmount
    };
  },
}
</script>

<style scoped lang="scss">
.wallet {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.user-title {
  display: flex;
  justify-content: flex-start;
}

.offer-description, .offer-label {
  display: flex;
  font-size: 17px;
  color: #eee;
  font-weight: 300;
  font-family: Roboto;
}

.offer-participants {
  max-width: 420px;
  flex-direction: column;
  padding: 12px 0;
  display: flex;
  margin-bottom: 12px;
}

.wallet-form {
  margin-top: 32px;
  max-width: 420px;
}

.icon-info {
  margin-right: 8px;
}

.participants-count {
  color: #fff;
  font-weight: 600;
  align-items: center;
  display: flex;
  margin-top: 4px;
  padding: 4px 16px;
  border-radius: 8px;
  font-size: 15px;
  height: 42px;
  background: rgba(255, 255, 255, 0.08);
  width: 100%;
  display: flex;
  flex: 1;
  font-family: Roboto;
}

.icon-info {
  width: 24px;
}

.icon-info.hot {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.settings-container {
  display: flex;
  margin-top: 32px;
  flex-direction: row;
  margin-bottom: 32px;
}

.wallet-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.btn-container {
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
}

.transactions {
  margin-top: 64px;
}

h4 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 14px;
  margin-bottom: 0;
}

.save-password {
  margin-top: 24px;
}

.transactions-container {
  .transactions-row {
    padding: 16px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    justify-content: space-between;
    color: #fff;

    .tx-ref {
      display: flex;
      flex: 1;
      font-size: 12px;
      flex-direction: column;
      font-family: Roboto;
      font-weight: 300;
    }
  }

  .tx-amount-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 140px;

    .tx-status {
      font-size: 16px;
      font-family: Roboto;
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-top: 4px;

      span {
        margin-left: 4px;;
      }

    }
  }

  .tx-date {
    font-size: 12px;
    font-family: Roboto;
    font-weight: 300;
    color: #eee;
  }
}

button:disabled {
  opacity: 0.8;
  pointer-events: none;
}

.error {
  color: red;
  margin-top: 8px;
  font-weight: 300;
  font-family: Roboto;
  font-size: 14px;

}

@media only screen and (max-width: 600px) {
  .settings-container {
    flex-direction: column;
    padding: 0 8px;
  }
}

.coins select {
  color: #000;
  max-width: 420px;

  margin-top: 16px;
}

.withdrawal-container {
  max-width: 420px;
  margin-top: 32px;
  margin-bottom: 12px;
}

.min-amount-description {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  margin-top: 26px;
  padding: 12px 16px;
  color: #fff !important;
  display: flex;
  justify-content: center;
}

.info-tx {
  display: flex;
  flex-direction: column;
}

.strong-title {
  text-transform: uppercase;
  font-size: 14px;
}

.stg {
  font-size: 13px;
  font-weight: bold;
}

.manual {
  padding: 0px 16px 16px 16px;
  margin-top: 16px;
  font-size: 15px;
  position: relative;
  border-radius: 12px;
  color: #fff;
  background: rgba(255, 255, 255, 0.08)
}

.dot-container {
  width: 40px;
  height: 40px;
  padding-top: 20px;
  padding-right: 10px;
  margin-left: -14px;
}
</style>
