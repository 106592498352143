<template>
  <div class="connect-modal" @click="handleClose">
    <div class="connect-container" @click.stop>
      <div class="content">
        <h6>Important!</h6>

        <p>To maximize your earnings:</p>

        <span><strong>Approve requests on TikTok:</strong> Make sure all requests are reviewed and approved to keep engagement high.</span>
        <span><strong>Post 5 times a day:</strong> Regular posts help maintain your audience and increase views.</span>
        <span><strong>Collect your daily reward of 50 cents for publishing 5 videos!</strong></span>
        <span><strong>Earn money:</strong> Your activity generates income — don’t miss the chance to make more!</span>
        <p style="margin-top: 20px">Follow these steps daily to succeed!</p>

        <button class="btn-solid" @click="close">ok</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "NotificationModal",
  setup(props, {emit}) {

    const close = () => {
      emit('close');
    };

    const handleClose = (event) => {
      if (event.target === event.currentTarget) {
        close();
      }
    };

    return {handleClose, close};
  }
});
</script>

<style scoped lang="scss">
.connect-container {
  background: #010314;
  border-radius: 20px;
  width: calc(100% - 16px);
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 320px;
  flex-direction: column;
  color: #fff;
}

h6 {
  font-size: 24px;
}

.connect-modal {
  z-index: 999999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

h2 {
  margin-bottom: 32px;
}

.connect-btn {
  background: rgb(21, 23, 38);
  width: calc(100% - 32px);
  display: flex;
  max-width: 280px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin-bottom: 16px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: rgb(21, 23, 54);
  }

  img {
    margin-right: 12px;
    margin-left: -12px;
  }
}

.connect-icon {
  width: 36px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;

  span {
    font-size: 15px;
    margin-top: 6px;
    margin-bottom: 6px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    padding: 16px 16px;
    text-align: left;
  }
}
</style>
