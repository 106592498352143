<template>
  <div class="user-profile">
    <div class="settings-container">
      <div class="user-profile-info" v-loading="loader">
        <div class="user-title">
          <h2 class="title">{{$t('settings')}}</h2>
        </div>

        <div class="offer-participants" style="border-bottom: none">
          <span class="offer-label">{{$t('account')}}</span>
          <div class="participants-count">
            <img class="icon-info hot" :src="profiles[0] ? profiles[0].avatar_url : '@/assets/tiktok.svg'">
            <strong>{{ (profiles && profiles[0]) && !loader ? profiles[0].username : '-' }}</strong>
          </div>
        </div>

        <!-- Альфа тестирование -->
        <div class="alpha-test-switcher">
          <label class="main-label">Alpha test</label>
          <el-switch type="checkbox" v-model="isAlphaTestEnabled" @change="toggleAlphaTest"></el-switch>
        </div>

        <div class="logout-container logout-desktop">
          <div class="logout" @click="logout">{{$t('logout')}}</div>
        </div>
      </div>
      <div class="user-profile-info">
        <div class="main-form settings-form">
          <div class="group-input">
            <label class="main-label">{{$t('email')}}</label>
            <input class="main-input" :value="userEmail"/>
          </div>

          <h4 class="change-pass-txt">{{$t('change_password')}}</h4>

          <div class="group-input" style="margin-top: 22px">
            <label class="main-label">{{$t('current_password')}}</label>
            <input class="main-input" v-model="password" type="password"/>
          </div>

          <div class="group-input">
            <label class="main-label">{{$t('new_password')}}</label>
            <input class="main-input" v-model="newPassword" type="password"/>
          </div>
          <button class="btn-solid save-password" @click="changePassword">{{$t('save_password')}}</button>

        </div>
      </div>

      <div class="logout-container mobile-logout">
        <div class="logout" @click="logout">{{$t('logout')}}</div>
      </div>
    </div>

  </div>
</template>

<script>
import { getAuth, signOut, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { onMounted, ref } from "vue";
import { fetchTikTokProfiles } from "@/firebase/queries";
import { ElNotification } from 'element-plus';

export default {
  name: "UserProfile",
  setup() {
    const userEmail = ref('');
    const auth = getAuth();
    const profiles = ref([]);
    const loader = ref(true);
    const password = ref('');
    const newPassword = ref('');
    const isAlphaTestEnabled = ref(false);

    onMounted(async () => {
      const user = auth.currentUser;
      const userUid = user.uid;
      profiles.value = await fetchTikTokProfiles(userUid);

      if (user) {
        userEmail.value = user.email;
      }

      const alphaTestValue = localStorage.getItem('alpha_test_enabled');
      if (alphaTestValue !== null) {
        isAlphaTestEnabled.value = alphaTestValue === 'true';
      }

      loader.value = false;
    });

    const toggleAlphaTest = () => {
      console.log(isAlphaTestEnabled.value , 'isAlphaTestEnabled.value ')
      localStorage.setItem('alpha_test_enabled', isAlphaTestEnabled.value);
    };

    const changePassword = () => {
      loader.value = true;
      const user = auth.currentUser;
      if (user && password.value && newPassword.value) {
        const credential = EmailAuthProvider.credential(user.email, password.value);
        reauthenticateWithCredential(user, credential).then(() => {
          updatePassword(user, newPassword.value).then(() => {
            ElNotification({
              title: 'Success',
              message: 'Password updated successfully',
              type: 'success',
            });
            loader.value = false;
          }).catch((error) => {
            ElNotification({
              title: 'Error',
              message: `Error updating password: ${error.message}`,
              type: 'error',
            });
            loader.value = false;
          });
        }).catch((error) => {
          ElNotification({
            title: 'Error',
            message: `Error reauthenticating: ${error.message}`,
            type: 'error',
          });
          loader.value = false;
        });
      } else {
        ElNotification({
          title: 'Warning',
          message: 'Please enter both the current password and the new password.',
          type: 'warning',
        });
        loader.value = false;
      }
    };

    const logout = () => {
      signOut(auth).then(() => {
        window.location.href = '/'
      }).catch((error) => {
        console.error("Ошибка выхода из системы:", error);
      });
    };

    return { logout, userEmail, profiles, loader, password, newPassword, changePassword, isAlphaTestEnabled, toggleAlphaTest };
  }
}
</script>

<style scoped lang="scss">
.user-profile {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.user-title {
  display: flex;
  justify-content: flex-start;
}

.offer-description, .offer-label {
  display: flex;
  font-size: 17px;
  color: #eee;
  font-weight: 300;
  font-family: Roboto;
}

.offer-participants {
  max-width: 420px;
  padding: 12px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-form {
  max-width: 420px;
}

.icon-info {
  margin-right: 8px;
}

.participants-count {
  color: #fff;
  font-weight: 600;
  align-items: center;
  display: flex;
  font-size: 15px;
  font-family: Roboto;
}

.mobile-logout {
  display: none;
}

.icon-info.hot {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.settings-container {
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
  flex-direction: row;
}

.user-profile-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.btn-container {
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
}

.logout {
  color: #EA5757;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.logout-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 64px;
  flex: 1;
}

.change-pass-txt {
  margin-top: 72px;
}

.group-input {
  margin-top: 32px;
}

.save-password {
  margin-top: 64px;
}

.mobile-logout{
  display: none ;
}

@media only screen and (max-width: 600px) {
  .settings-container {
    flex-direction: column;
    padding: 0 12px;
  }

  .logout-desktop {
    display: none;
  }

  .mobile-logout {
    display: flex;
    margin-top: 100px;
    margin-bottom: 50px;
    justify-content: center;
  }
}

.user-profile {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.alpha-test-switcher {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.alpha-test-switcher .main-label {
  margin-right: 10px;
}
</style>
