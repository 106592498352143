<template>
  <div class="connect-modal" @click="handleClose">
    <div class="connect-container" @click.stop>
      <h2>Connect account</h2>
      <div class="connect-btn" @click="connectAccount">
        <img class="connect-icon" src="@/assets/tiktok-white.svg"/>Tiktok
      </div>
      <div v-if="isAlphaTestEnabled" class="connect-btn" @click="connectYoutube">
        <img class="connect-icon" src="@/assets/youtube.svg"/>
        Youtube
      </div>

      <div v-if="isAlphaTestEnabled" class="connect-btn" @click="connectVk">
        <img class="connect-icon" src="@/assets/vk-logo.svg"/>
        VK
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from "firebase/auth";
import axios from "@/plugins/axios";
import {defineComponent, ref} from "vue";

export default defineComponent({
  name: "ConnectModal",
  setup(props, { emit }) {
    const auth = getAuth();
    const user = auth.currentUser;
    const isAlphaTestEnabled = ref(false);

    const connectAccount = async () => {
      const token = await user.getIdToken();
      axios.post('/tiktok-hash', { token }).then(({ data }) => {
        window.location.href = process.env.VUE_APP_TIKTOK_URL + '/tiktok-init/?hash=' + data;
      });
    };


    const alphaTestValue = localStorage.getItem('alpha_test_enabled');
    if (alphaTestValue !== null) {
      isAlphaTestEnabled.value = alphaTestValue === 'true';
    }

    const close = () => {
      emit('close');
    };

    const handleClose = (event) => {
      if (event.target === event.currentTarget) {
        close();
      }
    };

    const connectYoutube = async () => {
      const token = await user.getIdToken();
      axios.post('/youtube-hash', { token }).then(({ data }) => {
        window.location.href = process.env.VUE_APP_YOUTUBE_URL + '/youtube-init/?hash=' + data;
      });
    };


    const connectVk = async () => {
      const token = await user.getIdToken();
      axios.post('/vk-hash', { token }).then(({ data }) => {
        window.location.href = process.env.VUE_APP_VK_URL + '/vk-init/?hash=' + data;
      });
    };

    return { connectYoutube, connectAccount, handleClose, close, connectVk, isAlphaTestEnabled };
  }
});
</script>

<style scoped lang="scss">
.connect-container {
  background: #010314;
  border-radius: 20px;
  width: calc(100% - 16px);
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 320px;
  flex-direction: column;
  color: #fff;
}

.connect-modal {
  z-index: 999999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

h2 {
  margin-bottom: 32px;
}

.connect-btn {
  background: rgb(21, 23, 38);
  width: calc(100% - 32px);
  display: flex;
  max-width: 280px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin-bottom: 16px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: rgb(21, 23, 54);
  }

  img {
    margin-right: 12px;
    margin-left: -12px;
  }
}

.connect-icon {
  width: 36px;
}
</style>
