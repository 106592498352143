import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/scss/index.scss'
import router from './router';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {onAuthStateChanged} from "firebase/auth";
import { auth } from '@/firebase/init';
import i18n from './plugins/i18n';


const initApp = () => {
    onAuthStateChanged(auth, () => {

        const app = createApp(App)

        app.use(i18n)
        app.use(ElementPlus)
        app.use(router);
        for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
            app.component(key, component)
        }

        app.mount('#app')
    })
}

initApp()
