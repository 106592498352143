<template>
  <div class="user-profile" v-loading="loader">
    <!-- Заголовок -->
    <div class="user-header-container">
      <el-collapse v-model="activeNames" @change="handleChange" style="margin-bottom: 8px">
        <el-collapse-item title="My profile" name="1">
          <template #title>
            <div class="user-title" v-if="profiles && profiles[activeId]">
              <img class="prof-avatar-big" :src="profiles[activeId].avatar_url">

              <h2>
                <span>{{ profiles[activeId].username }}</span>
              </h2>
            </div>
          </template>
          <div class="profiles-list" v-if="profiles.length">
            <div v-for="(profile, key) in profiles" :key="key" @click="setActiveProfile(key)"
                 :class="['profile-container', {activeProfile: key === activeId}]">
              <img class="prof-avatar" :src="profile.avatar_url">
              <span>{{ profile.username }}</span>
              <img v-if="profile.type === 'VK'" src="@/assets/vk-logo.svg" class="prof-icon"/>
              <img v-if="profile.type === 'TIKTOK'" src="@/assets/tiktok-white.svg" class="prof-icon"/>
              <img v-if="profile.type === 'YOUTUBE'" src="@/assets/youtube.svg" class="prof-icon"/>
            </div>
            <div class="btn-solid btn-profile" @click="showConnect = true">+ Add account</div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- Статистика по профилю -->
    <div v-if="profiles && profiles[activeId]" class="stats-profile">
      <div class="stat-col"><span>Follower</span><strong>{{
          profiles[activeId].follower_count || profiles[activeId].subscriber_count
        }}</strong></div>
      <div class="stat-col" v-if="profiles[activeId].following_count !== undefined">
        <span>Following</span><strong>{{ profiles[activeId].following_count }}</strong></div>
      <div class="stat-col" v-if="profiles[activeId].likes_count !== undefined">
        <span>Likes</span><strong>{{ profiles[activeId].likes_count }}</strong></div>
      <div class="stat-col"><span>Videos</span><strong>{{ profiles[activeId].video_count }}</strong></div>
      <div class="stat-col" v-if="profiles[activeId].view_count !== undefined">
        <span>Views</span><strong>{{ profiles[activeId].view_count }}</strong></div>
    </div>

    <!-- Балансы пользователя -->
    <div class="offer-participants" v-if="balances">
      <div class="balances">
        <div v-for="coin in coins" :key="coin.symbol" v-show="balances[coin.symbol]">
          <img class="icon-info" :src="coin.preview">
          <strong>{{ parseFloat(Number(balances[coin.symbol]).toFixed(6)) }}</strong>
        </div>
      </div>
    </div>
    <!-- Список профилей -->
    <DailyProgress :user-id="currentUser"/>

    <!-- Список видео -->
    <h3 style="padding-top: 12px">My videos</h3>
    <template v-if="profiles.length">
      <div class="offers-cards" v-if="filteredVideos.length">
        <ActiveOfferCard v-for="task in filteredVideos" :key="task.id" :card="{...task}"/>
      </div>
      <div class="empty-offers" v-else>
        <div class="empty-desc">
          <img src="@/assets/grid-outline.svg" class="empty-icon">
          <h5>There are no videos published on this profile.</h5>
        </div>
        <router-link to="/offers">
          <button class="btn-solid">View offers</button>
        </router-link>
      </div>
    </template>

    <div v-else class="container-center">
      <div @click="showConnect = true" v-if="!loader">
        <button class="btn-solid">Connect tiktok Account</button>
      </div>
    </div>

    <!-- Модальное окно для подключения аккаунта -->
    <ConnectModal v-if="showConnect" @close="showConnect = false"/>
  </div>
</template>

<script>
import ActiveOfferCard from "@/components/ActiveOfferCard";
import {onMounted, ref, computed} from "vue";
import {fetchTasksForUser, fetchTikTokProfiles, getBalances, getWithdrawal} from "@/firebase/queries";
import {getAuth} from 'firebase/auth';
import axios from "@/plugins/axios";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "@/firebase/init";
import {orderBy} from "lodash";
import ConnectModal from "@/components/ConnectModal";
import {useRoute} from "vue-router";
import DailyProgress from "@/components/DailyProgress";

export default {
  name: "UserProfile",
  components: {DailyProgress, ConnectModal, ActiveOfferCard},
  setup() {
    const tasks = ref([]);
    const profiles = ref([]);
    const videos = ref([]);
    const route = useRoute();
    const loader = ref(true);
    const balances = ref(null);
    const showConnect = ref(false);
    const activeId = ref(0);
    const auth = getAuth();
    const user = auth.currentUser;
    const coins = ref([]);
    const userUid = route.params.id || user.uid;

    const currentUser = ref(userUid);


    const activeNames = ref([])
    const handleChange = (val) => {
      console.log(val)
    }

    const fetchCoins = async () => {
      const q = query(collection(db, "coins"));
      const querySnapshot = await getDocs(q);
      coins.value = querySnapshot.docs.map((doc) => doc.data());
    };

    const fetchVideos = async () => {
      const q = query(collection(db, 'videos'), where('userId', '==', route.params.id || user.uid));
      const querySnapshot = await getDocs(q);
      const videoData = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));

      const taskSnapshot = await getDocs(collection(db, "tasks"));
      const taskData = taskSnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));

      videoData.forEach((video, index) => {
        videoData[index].task = taskData.find(({id}) => video.offerId === id);
      });

      videos.value = orderBy(videoData, 'createTime', 'desc');
    };

    const filteredVideos = computed(() => {
      return videos.value.filter(video => {
        return video.profileId === profiles.value[activeId.value]?.id || (!video.profileId && activeId.value === 0);
      });
    });

    const setActiveProfile = (key) => {
      activeId.value = key;
    };

    onMounted(async () => {
      await fetchCoins();
      await fetchVideos();
      loader.value = true;

      const token = await user.getIdToken();
      try {
        await axios.post('/profile/update', {token});
        await fetchVideos();
      } catch (e) {
        console.log('Error updating profile:', e);
      }

      tasks.value = await fetchTasksForUser(userUid);
      profiles.value = await fetchTikTokProfiles(userUid);

      const balancesResponse = await getBalances(userUid);
      const withdrawalResponse = await getWithdrawal(userUid);
      const balanceResult = {};

      if (balancesResponse) {
        Object.entries(balancesResponse.balances).forEach(([key, value]) => {
          const withdrawal = (withdrawalResponse && withdrawalResponse[key]) || 0;
          balanceResult[key] = parseFloat(Number(value - withdrawal).toFixed(6));
        });
      }

      balances.value = balanceResult;
      loader.value = false;
    });

    return {
      tasks,
      profiles,
      balances,
      loader,
      coins,
      videos,
      activeId,
      showConnect,
      filteredVideos,
      setActiveProfile,
      handleChange,
      activeNames,
      currentUser
    };
  },
};
</script>

<style scoped lang="scss">
.user-profile {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.user-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}

.offer-description, .offer-label {
  display: flex;
  font-size: 17px;
  color: #eee;
  font-weight: 300;
  font-family: Roboto;
}

.offer-participants {
  width: calc(100% - 16px);
  padding: 18px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-info {
  margin-right: 8px;
}

.user-title {
  color: #fff;
}

.participants-count {
  color: #fff;
  font-weight: 600;
  align-items: center;
  display: flex;
  font-size: 15px;
  font-family: Roboto;
}

.icon-info.hot {
  width: 28px;
  height: 28px;
  margin-right: 4px;
  border-radius: 50%;
}

.btn-container {
  width: 120px;
  margin-left: 32px;
}

.offers-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px 16px;
}

.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
  min-height: 400px;
}

.stats-profile {
  display: flex;
  justify-content: flex-start;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  .stat-col {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    min-width: 120px;
    border-radius: 10px;
    margin-right: 8px;
    padding: 10px 10px;
    padding-left: 0px;
    margin-top: 8px;
    margin-bottom: 4px;

    span {
      font-size: 14px;
      font-weight: 300;
      font-family: Roboto;
    }

    strong {
      font-size: 16px;
      font-weight: 600;
      font-family: Roboto;
    }
  }
}

@media only screen and (max-width: 600px) {
  .balances {
    margin-left: 12px;
  }

  h2 {
    margin-bottom: 0;
    line-height: 1;
  }

  .profiles-list {
    flex-direction: column;

    .profile-container {
      margin-right: 0;
      margin-bottom: 4px;
    }
  }

  .stat-col {
    min-width: auto !important;
  }
  .stats-profile {
    display: grid;
    padding-left: 12px;

    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 8px 8px;

    .stat-col {
      margin-top: 2px;
      margin-bottom: 0;
    }
  }

  .btn-container {
    width: 100px;
  }

  .offer-participants {
    width: 100%;
    max-width: 100%;
  }
  .offer-label {
    font-size: 15px;
  }

  .offers-cards {
    display: flex;
    flex-direction: column;
  }

  .user-profile {
    padding: 0 8px;
  }
}

.participants-count-new {
  color: #fff;
  font-weight: 600;
  align-items: center;
  display: flex;
  margin-top: 4px;
  padding: 4px 16px;
  border-radius: 8px;
  font-size: 15px;
  height: 42px;
  width: 100%;
  display: flex;
  flex: 1;
  font-family: Roboto;
}

.balances {
  display: flex;
  flex: 1;
  flex-shrink: 0;
  color: #fff;
  flex-grow: 1;
  gap: 8px;

  img {
    max-width: 26px;
  }
}

.profiles-list {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
}

.profile-container {
  background: rgba(255, 255, 255, 0.08);
  padding: 12px 18px;
  margin-right: 8px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.08);

  &.activeProfile {
    border: 2px solid #E275FF;
  }
}

.prof-avatar {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-right: 8px;
}

.prof-avatar-big {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin-right: 8px;
}

.prof-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.profile-btn {
  border: none;
}

.btn-profile {
  font-size: 12px;
  font-weight: 400;
  border-radius: 8px;
}

.empty-offers {
  display: flex;
  flex-direction: column;
}

.empty-offers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  width: 100%;
  display: flex;
  flex: 1;
}

.empty-desc {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-icon {
  margin-bottom: 16px;
  width: 64px;
  opacity: 0.5;
}

.user-header-container {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.08) !important;
  margin-top: 12px;
  padding: 12px 12px;
}

.stats-profile, .balances {
  padding-left: 16px;
}
</style>
